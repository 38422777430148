<template>
  <div v-if="show">
    <van-overlay :show="show" class="overlay" @click="show = false">
      <img :show-menu-by-longpress="true" :src="url">
    </van-overlay>
  </div>

</template>

<script>

export default {
  name: 'selfTry',
  data() {
    return {
      show: false,
      url: '',
    }
  },
  methods: {
    getImg(show, imgurl) {
      this.show = show
      this.url = imgurl
    },

  },
}
</script>

<style lang="less" scoped>
.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;

  img {
    width: 70vw;
  }
}
</style>
