<template>
  <div class="application">
    <ul class="poster-box page-item f30">
      <template v-if="showPoster">
        <li @click="showTip('mtUrl')">
          <div class="page-icon2 mt"></div>
          <div>美团红包</div>
          <div class="tip">领55元</div>
          <wx-open-launch-weapp v-if="versions ==='WeChat'" appid="wxde8ac0a21135c07d"
            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
            path="/index/pages/h5/h5?weburl=https%3A%2F%2Fdpurl.cn%2FzdREuJoz&lch=cps:waimai:5:3e2af7bb1ebb6c24ec31badaeb1a06e8:001:33:100142&f_token=1&f_userId=1">
            <script type="text/wxtag-template">
              <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; opacity: 0"></div>
            </script>
          </wx-open-launch-weapp>
        </li>
        <li @click="showTip('elmUrl')">
          <div class="page-icon2 elm"></div>
          <div>饿了么红包</div>
          <div class="tip">领55元</div>
          <wx-open-launch-weapp v-if="versions ==='WeChat'" appid="wxece3a9a4c82f58c9"
            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
            path="commercialize/pages/taoke-guide/index?scene=06e274c028c54179b108c0e1926d3e00">
            <script type="text/wxtag-template">
              <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; opacity: 0"></div>
            </script>
          </wx-open-launch-weapp>
        </li>
        <!-- <li @click="showTip('ddUrl')">
          <div class="page-icon2 dd"></div>
          <div>滴滴</div>
          <div class="tip">领36元</div>
        </li> -->
      </template>
      <li @click="goPage('/ticky')">
        <div class="page-icon2 share"></div>
        <div>分享好友</div>
      </li>
      <li @click="goPage('/detailed')">
        <div class="page-icon2 course"></div>
        <div>新人教程</div>
      </li>
      <li @click="showGroup()">
        <div class="page-icon2 group"></div>
        <div>福利群</div>
      </li>
    </ul>

    <van-popup v-model="showGroupImg">
      <img class="groupImg" :src="groupImgUrl">
    </van-popup>
  </div>
</template>

<script>
import { mallApi } from '@/api'
import { mapState } from 'vuex'

export default {
  name: 'Application',
  data() {
    return {
      urlMap: {
        elmUrl: 'https://u.ele.me/LkNn8riw',
        mtUrl: 'https://click.meituan.com/t?t=1&c=2&p=ETxrS75zMJEM',
        ddUrl: 'https://didi.cn/bm6Ae37?source_id=xifanbwc'
      },
      showGroupImg: false,
      groupImgUrl: '',
    }
  },
  computed: {
    ...mapState('user', ['agentId', 'agentConfig', 'isApp', 'appConfigJson', 'versions']),

    showPoster() {
      let item = this.appConfigJson.hideCps.indexOf(this.agentId)
      return item >= 0 ? false : true
    }
  },
  created() {
    if (this.agentConfig.ELM_CPS_URL) {
      this.urlMap.elmUrl = this.agentConfig.ELM_CPS_URL
    }
    if (this.agentConfig.MT_CPS_URL) {
      this.urlMap.mtUrl = this.agentConfig.MT_CPS_URL
    }

    this.getGroupUrl()
  },
  methods: {
    showTip(type) {
      let url = this.urlMap[type]
      if (!url) {
        this.$toast('平台未设置, 请联系管理员')
        return
      }

      if (this.isApp) {
        uni.postMessage({
          data: {
            type: 'openUrlOnBrowser',
            url
          }
        })
      } else {
        window.location.href = url
      }
    },

    // 获取客服信息--- 福利群二维码
    getGroupUrl() {
      mallApi({
        trxCode: 'XF906',
      }).then((res) => {
        this.groupImgUrl = res.wechatImgLink
      })
    },

    showGroup() {
      if (!this.groupImgUrl) {
        this.$toast('平台未设置')
        return
      }
      this.showGroupImg = true
    },

    // 页面跳转
    goPage(path) {
      this.$router.push({ path: path })
    },
  }
}
</script>

<style lang='less' scoped>
.poster-box {
  padding: 3vw 0;
  margin: 0 12px;
  li {
    display: inline-block;
    width: 20%;
    text-align: center;
    position: relative;
  }
  .tip {
    position: absolute;
    top: -4px;
    right: 0;
    font-size: 10px;
    background: #ff5b50;
    color: #fff;
    border-radius: 2px;
    padding: 0 2px;
  }
  .page-icon2 {
    width: 40px;
    height: 40px;
    transform: scale(0.9);
  }
  .mt {
    background-position: -113px -20px;
  }
  .elm {
    background-position: -226px -74px;
  }
  .dd {
    background-position: -341px -20px;
  }
  .share {
    background-position: -390px -20px;
  }
  .course {
    background-position: -438px -20px;
  }
  .group {
    background-position: -293px -74px;
  }
}
.groupImg {
  width: 70vw;
}
</style>