<template>
  <div class="sysNotice">
    <div class="page-icon2"></div>
    <div class="content">
      <van-notice-bar background="transparent" color="#222">
        <van-swipe vertical class="notice-swipe" :autoplay="20000" :show-indicators="false">
          <van-swipe-item v-for="item in noticeList" :key="item.noticeId">{{item.noticeContext}}</van-swipe-item>
        </van-swipe>
      </van-notice-bar>
    </div>
  </div>

</template>

<script>
import { mallApi } from '../api'

export default {
  name: 'SysNotice',
  props: {
    text: {
      type: String,
      default: '',
    },
    speed: {
      type: String,
      default: '40',
    },
  },
  data() {
    return {
      noticeList: [
        {
          noticeId: 0,
          noticeContext:
            '感谢关注本地试吃平台，自用省钱，分享赚钱，祝您用餐愉快',
        },
      ],
    }
  },
  created() {
    this.agentId = localStorage.getItem('agent')
    this.getSysNotice()
  },
  methods: {
    // 获取系统公告
    getSysNotice() {
      if (!this.agentId) {
        return
      }
      let params = {
        trxCode: 'XF915',
        agentId: this.agentId,
      }
      mallApi(params).then((res) => {
        if (res.noticeList && res.noticeList.length > 0) {
          this.noticeList = res.noticeList
        }
      })
    },
  },
}
</script>

<style lang='less' scoped>
.sysNotice {
  padding: 0vw 5vw;
  font-size: 3.5vw;
  display: flex;
  align-items: center;
  .page-icon {
    width: 20px;
    height: 20px;
    background-position: -15px -88px;
  }
  .content {
    flex: 1;
  }
  .page-icon2 {
    width: 12px;
    height: 12px;
    background-position: -226px -158px;
    transform: scale(1.2);
    margin-right: -6px;
  }
}
.notice-swipe {
  height: 40px;
  line-height: 40px;
}
</style>