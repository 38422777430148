<template>
  <div class="page-item foodInfo">
    <div class="left">
      <img class="img-box" v-lazy="data.avatar" alt="">
    </div>
    <div class="content">
      <div class="name f36">{{data.shopName}}</div>
      <div class="info2 grey6 f34 margin-tb1">
        <span class="info2">
          <template v-if="data.channelOutId === 'C004'">
            <img class="channel-icon vertical-middle" src="../assets/home/elm.png" />
            <span class="vertical-middle margin-l1">饿了么</span>
          </template>
          <template v-if="data.channelOutId === 'C003'">
            <img class="channel-icon vertical-middle" src="../assets/home/mt.png" />
            <span class="vertical-middle margin-l1">美团</span>
          </template>

          <span class="margin-l2">{{data.distance | distanceFormat}}</span>
        </span>
        <span>还剩 <strong style="color: #555;">{{data.stock}}</strong> 份</span>
      </div>
      <div v-if="data.activityFlag === 'Y'">
        <span class="plus f30">满￥ {{data.price}} 返 ￥ {{data.vipProfit? data.vipProfit:data.profit }}</span>
      </div>
      <div v-else>
        <span class="plus f30">plus会员:满 {{data.price}} 返 {{data.vipProfit? data.vipProfit:data.profit }}</span>
        <span class="noPlus f30 margin-l2">非会员:满 {{data.price}} 返 {{data.profit}}</span>
      </div>
      <div class=" f32 info2 grey6" :class="data.activityFlag === 'Y' ? 'margin-t1':'margin-t2'">
        <span>抢单时间: {{data.businessTime}}</span>
        <span class="mainBut-plain f34" :class="data.stock <= 0 ? 'disabled':''" @click="goPage(data)">
          <strong>{{data.stock > 0 ? '立即抢单':'已抢光'}}</strong>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormat } from '../utils/utils'
import { mapState } from 'vuex'

export default {
  name: 'FoodInfo',
  props: {
    data: {},
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('user', ['agentId', 'custMsg']),
  },
  filters: {
    distanceFormat(val) {
      val = Number(val)
      if (val < 1000) {
        return val + 'm'
      } else {
        let num = val / 1000
        return num.toFixed(1) + '公里'
      }
    },
  },
  mounted() { },
  methods: {
    // 跳转我的试吃和立即试吃
    goPage(item) {
      let checkTime = this.checkTime(item.businessTime)
      if (!checkTime) {
        this.$toast('未到店铺抢单时间, 敬请期待~')
        return
      }
      sessionStorage.setItem('shoptext', JSON.stringify(item))
      this.$router.push({
        path: '/apply',
        hash: `#/apply?&spuId=${item.spuId}&shopId=${item.shopId}&isActivity=${item.activityFlag}`,
        query: { agentId: this.agentId, parentId: this.custMsg.custId, spuId: item.spuId, shopId: item.shopId, isActivity: item.activityFlag },
      })
    },

    // 检查当前时间是否在抢单时间内
    checkTime(val) {
      if (!val) return
      let nowVal = dateFormat(new Date(), 'HHMM')
      let startVal = val.split('-')[0].replace(':', '')
      let endVal = val.split('-')[1].replace(':', '')
      if (startVal >= endVal) {
        // 存在跨天情况
        return (startVal <= nowVal && nowVal < 2400) || (0 <= nowVal && nowVal < endVal)
      } else {
        return startVal <= nowVal && nowVal < endVal
      }
    }
  },
}
</script>

<style lang='less' scoped>
.foodInfo {
  display: flex;

  > .left {
    padding-top: 2vw;
    .img-box {
      border-radius: 2vw;
      width: 20vw;
      height: 20vw;
      object-fit: cover;
    }
  }
  > .content {
    flex: 1;
    margin-left: 2vw;

    .name {
      width: 100%;
      font-weight: bold;
      color: #333;
    }
    .info2 {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
.channel-icon {
  width: 4vw;
}
</style>