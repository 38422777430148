<template>
  <div class="app-contianer">
    <PageBg bgImg />

    <div id="app-content">
      <!-- <div class="weni white" v-if="agentConfig.MP_CODE_URL && login">
        <div class=" f34">
          <p>欢迎来到{{agentConfig.bwcName}}</p>
          <p class="margin-t1">关注公众号, 享专属服务</p>
        </div>
        <div class="but mainBgColor" @click="goMp()">立即关注</div>
      </div> -->

      <!--    地址和登录-->
      <div class="title-contianer">
        <div class="allPlace">
          <div class="cityName" @click="changeCity()">
            {{ cityName }}
            <van-icon name="arrow" />
          </div>
          <div class="place" @click="changeLocation('/map')">
            <van-icon name="location-o"></van-icon>
            <span class="txt-ellipsis">{{ address }}</span>
          </div>
        </div>
      </div>

      <!--    轮播图-->
      <div class="carousel-box">
        <img v-if="advertisList.length === 0 && loading === false" :src="DefaultCarousel" @click="goPage('/detailed')" />
        <van-swipe v-else class="my-swipe" :autoplay="5000" indicator-color="white" :show-indicators="true">
          <van-swipe-item v-for="item in advertisList" :key="item.id">
            <img class="carousel-img" :src="item.pics" @click="swipe(item)">
          </van-swipe-item>
        </van-swipe>
      </div>

      <SysNotice text="感谢关注本地试吃平台，自用省钱，分享赚钱，祝您用餐愉快" />

      <Application />

      <div class="selected-title">
        <div class="category-group">
          <template v-if="listquary.isActivity === 'ALL'">
            <span class="item" :class="listquary.isActivity === 'ALL' ? 'active' : ''"
              @click="chanegActivity('ALL')">霸王餐</span>
          </template>
          <template v-else>
            <span class="item" :class="listquary.isActivity === 'N' ? 'active' : ''"
              @click="chanegActivity('N')">霸王餐</span>
            <span class="item" :class="listquary.isActivity === 'Y' ? 'active' : ''" v-if="agentConfig.activity === 'BWC'"
              @click="chanegActivity('Y')">联盟商家</span>
          </template>
        </div>
        <div class="selected-seach">
          <span class="f34 margin-lr2" :class="listquary.isSale === '1' ? 'font-bold' : ''" @click="isSale()">只看可抢</span>
          <van-switch class="switch" size="11px" v-model="listquary.isSale" active-value="1" inactive-value="0"
            @change="searchShop()" />
          <el-dropdown trigger="click" @command="handleCommand">
            <span>
              {{ listquary.distance | distanceFilter }}
              <i class="el-icon-caret-bottom"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="item in distanceList" :key="item.value" :command="item">
                <span>{{ item.label }}</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>

      <div class="search-box">
        <van-search v-model="listquary.keywords" shape="round" background="transparent" placeholder="请输入搜索关键词"
          @search="searchShop()" />
      </div>

      <!-- 店铺列表 -->
      <div>
        <FoodInfo :data="item" v-for="(item, index) in footlist" :key="index" />
      </div>
      <div class="page-item load-box" v-if="loading">
        <i class="el-icon-loading"></i>
        <span>请稍等</span>
      </div>
      <el-empty :description="remsg" v-if="footlist.length === 0" :image="emptyimg" :image-size="100"></el-empty>
      <div class="load-text" v-if="footlist.length">{{ loadtext }}</div>
    </div>

    <!-- 客服浮框 -->
    <div class="service-box" v-if="this.agentConfig.SERVICE_WECHATCODE" @click="showServiceInfo()">
      <div class="page-icon"></div>
    </div>

    <!-- 话费推广弹框 -->
    <van-popup v-model="showPromotePopup" class="promotePopup">
      <img class="bgImg" src="../../assets/home/cpsPromote.png" alt="">
      <div class="content">
        <div class="title white">领红包，下单更优惠</div>
        <ul>
          <li>
            <div class="red">￥<span class="f50">56</span></div>
            <div class="slicer">
              <div class="circle top" style="background: #ff9250"></div>
              <div class="line"></div>
              <div class="circle bottom" style="background: #ff8849"></div>
            </div>
            <div class=" flex-1">
              <div>每餐最高可领</div>
              <div class="icon"><img src="../../assets/home/mt.png" alt=""></div>
            </div>
            <div class="but" @click="goCps('mt')">免费领取
              <wx-open-launch-weapp v-if="versions === 'WeChat'"
                style="position: absolute; top: 0; left: 0; width: 100%; height: 100%" appid="wxde8ac0a21135c07d"
                path="/index/pages/h5/h5?weburl=https%3A%2F%2Fdpurl.cn%2FzdREuJoz&lch=cps:waimai:5:3e2af7bb1ebb6c24ec31badaeb1a06e8:001:33:100142&f_token=1&f_userId=1">
                <script type="text/wxtag-template">
                  <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; opacity: 0"></div></script>
              </wx-open-launch-weapp>
            </div>
          </li>
          <li>
            <div class="red">￥<span class="f50">66</span></div>
            <div class="slicer">
              <div class="circle top" style="background: #ff8748"></div>
              <div class="line"></div>
              <div class="circle bottom" style="background: #ff8043"></div>
            </div>
            <div class=" flex-1">
              <div>翻倍红包天天领</div>
              <div class="icon"><img src="../../assets/home/elm.png" alt=""></div>
            </div>
            <div class="but" @click="goCps('elm')">免费领取
              <wx-open-launch-weapp v-if="versions === 'WeChat'"
                style="position: absolute; top: 0; left: 0; width: 100%; height: 100%" appid="wxece3a9a4c82f58c9"
                path="commercialize/pages/taoke-guide/index?scene=06e274c028c54179b108c0e1926d3e00">
                <script type="text/wxtag-template">
                  <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; opacity: 0"></div>
                      </script>
              </wx-open-launch-weapp>
            </div>
          </li>
        </ul>
        <div class="tac white f30">今日最新红包已更新</div>
      </div>
      <div class="footer tac white" @click="showPromotePopup = false">
        <van-icon name="cross" />
      </div>
    </van-popup>

    <selfTry ref="selfTry"></selfTry>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import selfTry from './compents/selfTry.vue'
import { getlist, getToken, tfoodApi } from '@/api'
import { getScroll, getScrolltop, changeTitle, dateFormat, isHttp } from '@/utils/utils'
import FoodInfo from '../../components/FoodInfo.vue'
import SysNotice from '../../components/SysNotice.vue'
import Application from './compents/Application.vue'
import PageBg from '@/components/PageBg.vue'
const agentCustomize = require('@/utils/agentCustomize')


const distanceList = Object.freeze([
  { value: '3000', label: '3公里' },
  { value: '5000', label: '5公里' },
  { value: '10000', label: '10公里' },
  { value: '15000', label: '15公里' },
])

export default {
  name: 'Home',
  components: { FoodInfo, SysNotice, Application, PageBg, selfTry },
  data() {
    return {
      DefaultCarousel: agentCustomize.homeDefaultCarousel, //轮播图列表
      emptyimg: agentCustomize.emptyShop, //空图片
      distanceList: distanceList, //查询范围列表
      listquary: {
        trxCode: 'MS101',
        agentId: localStorage.getItem('agent'),
        page: 1,
        pageSize: 50,
        lng: '116.40717',
        lat: '39.90469',
        distance: '3000',
        categoryGroup: 'FOOD',
        isActivity: 'N',
        keywords: '',
        isSale: '1'
      }, //查询列表信息
      trying: 'N', //是否是自己选择的试吃标志
      loading: false,
      listLoading: false,
      footlist: [], //信息列表
      pageFlag: false, //分页是否完成
      loadtext: '上拉加载',
      remsg: '', //提示信息
      login: false,
      advertisList: [],
      showPromotePopup: false
    }
  },
  computed: {
    ...mapState('user', ['custMsg', 'location', 'address', 'cityName', 'agentConfig', 'isApp', 'appConfigJson', 'versions']),
  },
  filters: {
    distanceFilter(val) {
      let item = distanceList.find(v => v.value === val)
      return item ? item.label : val
    }
  },
  watch: {
    address() {
      this.getShopList()
    },
    'listquary.isSale': function (val) {
      localStorage.setItem('shopSale', val)
    }
  },
  created() {
    this.login = localStorage.getItem('H5token')
    this.agentId = localStorage.getItem('agent')
    changeTitle(this.agentConfig.bwcName) // 修改页面名称
    // 是否展示话费推广弹框
    if (this.appConfigJson.hideCps.indexOf(this.agentId) === -1) {
      this.promotePopup()
    }
    // 设置默认搜索范围
    this.listquary.distance = this.agentConfig.SEARCH_DISTANCE || '3000'
    // 设置默认商家类型
    this.listquary.isActivity = this.agentConfig.HOME_LIST_ACTIVITY || 'N'
    // 设置默认查看可抢
    let isSale = localStorage.getItem('shopSale')
    this.listquary.isSale = isSale || '1'

    this.getCarouselList()
    if (this.address) {
      this.getShopList()
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrollListener)
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollListener)
    clearTimeout(this.timeOut)
  },
  methods: {
    ...mapActions('user', ['setLocationCity']),

    // 获取商家列表
    getShopList() {
      if (!this.agentId) return
      if (this.listLoading) return
      this.listquary.lng = this.location.longitude
      this.listquary.lat = this.location.latitude
      this.listLoading = true
      getlist(this.listquary).then((res) => {
        this.listLoading = false
        if (res.foodGoodList) {
          res.foodGoodList.forEach((item) => {
            this.footlist.push(item)
          })
          if (res.foodGoodList.length < this.pageSize) {
            // 分页结束
            this.pageFlag = true
            if (this.listquary.page === 1) {
              this.remsg = res.rspMsg
            } else {
              this.loadtext = '没有更多了'
            }
          }
        } else {
          // 分页结束
          this.pageFlag = true
          if (this.listquary.page === 1) {
            this.remsg = res.rspMsg
          } else {
            this.loadtext = '没有更多了'
          }
        }
      })
    },

    // 获取轮播图
    getCarouselList() {
      this.loading = true
      let params = {
        trxCode: 'MS003',
        agentId: this.agentId,
        pageSize: 10
      }
      tfoodApi(params).then(res => {
        this.loading = false
        if (res.advertisList.length > 0) {
          this.advertisList = res.advertisList
        }
      })
    },

    //  轮播图路径跳转
    swipe(item) {
      if (!item.url) return
      let urlStr = encodeURI(item.url)

      if (!isHttp(item.url)) {
        this.$router.push({ path: item.url })
        return
      }


      if (this.isApp) {
        uni.postMessage({
          data: {
            type: 'openUrlOnBrowser',
            url: urlStr
          }
        })
      } else {
        window.location.href = urlStr
      }
    },

    // 页面跳转
    goPage(path) {
      this.$router.push({ path })
    },

    // 切换城市
    changeCity() {
      if (this.agentConfig.LOCATION_SPAN_LIMIT === 'N') {
        this.$toast('不允许跨城市定位')
      } else {
        this.goPage('/cityList')
      }
    },

    // 选择定位
    changeLocation() {
      if (this.agentConfig.LOCATION_SPAN_LIMIT === 'N') {
        this.setLocationCity()
      }
      this.goPage('/map')
    },

    // 选择排序类型
    handleCommand(val) {
      this.footlist = []
      this.pageFlag = false
      this.listquary.distance = val.value
      this.listquary.page = 1
      this.getShopList()
    },

    // 搜索商品
    searchShop() {
      this.footlist = []
      this.listquary.page = 1
      this.getShopList()
    },

    // 切换商品类型
    chanegCategory(val) {
      this.listquary.categoryGroup = val
      this.footlist = []
      this.listquary.page = 1
      this.getShopList()
    },

    // 切换商品类型
    chanegActivity(val) {
      this.listquary.isActivity = val
      this.footlist = []
      this.listquary.page = 1
      this.getShopList()
    },

    // 只看可抢
    isSale() {
      if (this.listquary.isSale === '1') {
        this.listquary.isSale = '0'
      } else {
        this.listquary.isSale = '1'
      }

      this.footlist = []
      this.listquary.page = 1
      console.log(this.listquary.isSale);
      this.getShopList()
    },

    // 显示推广弹框
    promotePopup() {
      let date = dateFormat(new Date(), 'YYYY-mm-dd')
      let hour = dateFormat(new Date(), 'HH')
      if (hour < 15) {
        date = date + '上午'
      } else {
        date = date + '下午'
      }
      let oldDate = localStorage.getItem('homePromotePopup')
      if (date != oldDate) {
        this.showPromotePopup = true
        localStorage.setItem('homePromotePopup', date)
      }
    },

    // 显示客服二维码
    showServiceInfo() {
      if (!this.agentConfig.SERVICE_WECHATCODE) {
        this.$toast('平台未设置')
        return
      }

      this.$refs.selfTry.getImg(true, this.agentConfig.SERVICE_WECHATCODE)
    },

    // 展示公众号二维码
    goMp() {
      this.$refs.selfTry.getImg(true, this.agentConfig.MP_CODE_URL)
    },

    goCps(val) {
      if (val === 'mt') {
        let url = this.agentConfig.MT_CPS_URL || 'https://click.meituan.com/t?t=1&c=2&p=HnvzUr5zMJEM'
        window.location.href = url
      }

      if (val === 'elm') {
        let url = this.agentConfig.ELM_CPS_URL || 'https://s.click.ele.me/Mut6JSu'
        window.location.href = url
      }

    },

    // 滚动监听
    scrollListener() {
      //总高度=滚动条滚动的高度+cliebtHeight（要是想不到底部就加载数据可以写成）
      //offsetHeight +clientHeight>=scrollHeight-n (n是任意数字)
      var hegit = getScrolltop()
      var scroll = getScroll()
      if (hegit >= scroll) {
        if (this.pageFlag === false && this.listLoading === false) {
          this.listquary.page = this.listquary.page + 1
          this.getShopList()
        }
      }
    },
  },
}
</script>

<style lang="less" scoped>
.title-contianer {
  padding: 0 4vw;

  .place {
    display: inline-block;
    width: 78%;
    height: 10vw;
    line-height: 10vw;
    font-size: 3.8vw;
    font-weight: bold;
    color: #222;

    i {
      font-weight: bold;
      margin-right: 1vw;
      font-size: 4.6vw;
      vertical-align: middle;
    }

    .txt-ellipsis {
      display: inline-block;
      width: 88%;
      color: #333;
      vertical-align: middle;
    }
  }

  .cityName {
    display: inline-block;
    width: 22%;
    font-size: 3.8vw;
    text-align: left;
    font-weight: bold;
  }
}

.weni {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2vw 3vw;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;

  .but {
    padding: 1vw 3vw;
    border-radius: 6px;
    font-size: 3.4vw;
  }
}

.carousel-box {
  width: 100%;
  min-height: 32vw;
  box-sizing: border-box;
  padding: 0 12px;

  img {
    width: 100%;
    max-height: 32vw;
    border-radius: 3vw;
    object-fit: cover;
  }
}

.stay-contianer {
  .stay-title {
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 2vw;

    i {
      color: #ff302b;
    }

    .stayTitle {
      color: #333;
      font-size: 3.8vw;
    }
  }

  .foodInfo {
    margin-top: 2vw;
    display: flex;

    >.left {
      display: flex;
      align-items: center;

      .img-box {
        border-radius: 2vw;
        width: 18vw;
        height: 18vw;
        text-align: center;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 2vw;
        }
      }
    }

    >.content {
      flex: 1;
      margin: 0 2vw;

      .spuName {
        font-size: 4vw;
        font-weight: bold;
        color: #333;
      }

      .shopName {
        font-size: 4vw;
        margin: 1.5vw 0;
      }
    }

    >.right {
      width: 22vw;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: center;

      .distance {
        color: #666;
        vertical-align: middle;
        text-align: right;

        img {
          margin-left: 1vw;
          vertical-align: middle;
          width: 5vw;
        }
      }
    }
  }
}

.selected-title {
  margin: 3vw 4vw;
  margin-top: 4vw;

  .selected-text {
    display: inline-block;
    text-align: center;
    font-weight: bold;
    font-size: 5vw;
    color: #333;

    img {
      width: 5vw;
      display: inline-block;
      vertical-align: middle;
      margin: 0 2vw;
    }
  }

  .category-group {
    font-size: 5vw;
    color: #333;

    .item {
      margin-right: 4vw;
    }

    .item.active {
      font-weight: bold;
    }
  }

  .selected-seach {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: -4vw;
    color: #606266;
  }

  .switch {
    margin-right: 2vw;
  }
}

.load-text {
  margin: 4vw 0;
  text-align: center;
  font-size: 3.8vw;
  color: #999999;
}

.load-box {
  height: 26vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 3vw;
  color: #9a7aee;
}

.search-box {
  width: 100%;
}

.service-box {
  position: fixed;
  left: 12px;
  bottom: 18vh;
  z-index: 999;
  background: #fff;
  border-radius: 6px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  .page-icon {
    width: 26px;
    height: 26px;
    background-position: -159px -11px;
  }
}

.promotePopup {
  width: 310px;
  background: transparent;
  font-size: 14px;

  .content {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 154px 30px 0 35px;
    box-sizing: border-box;
  }

  .title {
    font-size: 16px;
  }

  li {
    display: flex;
    align-items: center;
    background: #fff;
    padding: 12px 8px;
    border-radius: 4px;
    margin: 12px 0;
    height: 44px;
  }

  .but {
    position: relative;
    background: linear-gradient(180deg, #fd7d48 0%, #fb5928 100%);
    color: #fff;
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 99px;
  }

  .icon {
    width: 18px;
    margin-top: 4px;
  }

  .slicer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 44px;
    margin-left: 2px;
    margin-right: 4px;

    .circle {
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }

    .top {
      margin-top: -18px;
    }

    .line {
      width: 1px;
      background: #eee;
      margin: 6px 0;
      flex: 1;
    }

    .bottom {
      margin-bottom: -18px;
    }
  }
}

.van-search__content {
  background-color: #fff;
  padding: 4px 12px;
}

.van-search {
  padding: 0 12px;
}
</style>
